import {reactive, toRefs} from "vue";
import service from "@/utils/axios";
import {useRoute} from "vue-router";
import moment from "moment";
import {onPageLoadingEnd, onPageLoadingStart} from "@/utils/DLoading";
import {message} from "ant-design-vue";

export interface ICheckStudent {
  totalLessons: number;                 //  总课次
  studentId: number;                    //  学生的id
  classId: number;                      //  班级ID
  classScheduleId: number;              //  班级排课表
  id: number;                           //  课程课次进度表的id主键,如果是开始点名这里就是null，如果是考勤这里就是有,同时在修改考勤里面添加学生的时候也会没有，所以不能已这个来判断
  dwAuthorizeSaleCourseId: number;      //  顾问课程授权表的id
  courseId: number;                     //  课程的id
  subjectName: string;                  //  课程的学科
  reduce: boolean;                      //  是否课消，true课消，false不课消 选中传true
  giveout: boolean;                     //  是否发放教材 发放giveout  选中发教材 传TRUE ,不发教材 传FALSE
  courseIndex: boolean;                 //  该字段前端展示是否有教材 接收  true:不需要发教材，false需要发教材
  sex: number;                          //  学生的性别
  campusId: number;                     //  校区ID
  avatar: string;                       //  头像 积分卡发放IP形象也取这个字段
  check: boolean;                       //  是否到了，true到了，false未到 选中传true
  integralCount: number;                //  发放积分数
  push: boolean;                        //  是否推送，true推送，false不推送  选中传true
  submitWay: number;                    //  1顾问端，2老师端，3授课端      默认值3
  courseName: string;                   //  课程的名字
  topicId: number;                      //  课题id
  scheduleIndex:  number;               //  上课课次
  teacherId: number;                    //  老师id
  classCheckId: number;                 //  班级考勤ID
  studentName:  string;                 //  学生的名字
  isItAttendance: boolean;              //  是考勤吗，如果不是就是false是的话就是true
  topicName: string;                    //  课题名字
  remainingLessons: number;             //  剩余课次
  status: number;                       //  学生类型, 1:正常、2:补课、3:插班、4:批量课消、5:试听)
  isChecked: boolean,
}


export interface ICheckStuState {
  classTimes: string;
  topicName: string;
  studentList: ICheckStudent[];
  studentNotCheckList: ICheckStudent[];
  isBut:boolean,
}

export function checkStudentBill() {
  const route = useRoute();

  let stateRef = reactive(<ICheckStuState>{
    classTimes: <string>route.query.classTime,
    topicName: route.query.topicName,
    studentList: [],
    studentNotCheckList: [],
    isBut:false,
  });

  let refData = toRefs(stateRef)
  let comClassTime = moment(<string>route.query.beginDate).format('M月DD日') + `（${route.query.startTime}-${route.query.endTime}）`


  const onCheckChange = () => {
    // 学生选择签到
      let isCheckedList:Array<boolean> = []
      stateRef.studentList.forEach(v=>{
        isCheckedList.push(v.check)
      })
      if ( isCheckedList.indexOf(true) === -1 ) {
          stateRef.isBut = true
      }else {
          stateRef.isBut = false
      }
  }

  const onSendChange = () => {
    // 学生选择发放教材
    console.log('send')
  }

  const getInfo = async () => {
    onPageLoadingStart();
    const r: ICheckStudent[] = await service.get(`/dwart/an_teaching/attendance/list/${route.query.scheduleId}`)
    let _list = r.map(v=>{return{...v, isChecked: v.check}});
    stateRef.studentList = _list.filter(v=>v.topicId)
    stateRef.studentNotCheckList = _list.filter(v=>!v.topicId)
      await onCheckChange()
    onPageLoadingEnd()
  }

  const init = async () => {
    await getInfo()
  }

  const onRefresh = async () => {
    await getInfo()
  }

  const onConfirm = async () => {
      if ( stateRef.isBut ) return
    message.loading({
      content: '提交考勤中...',
      key: 'submit',
      duration: 0,
    });
    let _list = stateRef.studentList.concat(stateRef.studentNotCheckList)
    _list.forEach( v=> {
      v.reduce = v.check;
      v.push = v.check;
    })
    const r: any = await service.post(`/dwart/an_teaching/attendance/making/attendance/${route.query.scheduleId}/and/1`, {
      classScheduleId: route.query.scheduleId,
      scheduleIndexStr: route.query.courseIndex,
      listModels: _list,
    })
    if (r.result){
      message.success({
        content: r.message,
        key: 'submit',
        duration: 1,
      })
    }else{
      message.error({
        content: r.message,
        key: 'submit',
        duration: 1,
      })
    }
  }

  return {
    onCheckChange,
    onSendChange,
    onConfirm,
    onRefresh,
    init,
    refData,
    comClassTime,
  }
}
